import { navigate } from "@reach/router";
import SalesAPI from "@src/api/SalesOrder";
import fuzzyMatch from "@src/libs/utils/fuzzyMatch";
import { dateFormat } from "@src/libs/utils/stringFormat";
import printStruk from "@src/services/PrintStruk";
import { sendToTotem } from "@src/services/Totem";
import { observable, runInAction } from "mobx";
import { Model } from "../libs/model/model";
import ConfigStore from "./config";
import CustomerStore from "./customer-repo";
import { CustomerSingle } from "./customer-single";
import { OrderItem, OrderProductSingle, OrderSingle } from "./order-single";
import ProductStore from "./product-repo";
import { ProductSingle } from "./product-single";


export class OrderRepository extends Model {
  list: OrderItem[] = OrderItem.hasMany(this);
  idCurrentOrder = 0;
  idDetailOrder = 0;
  tempOrder: OrderSingle = OrderSingle.childOf(this);
  updateOrder: OrderItem = OrderItem.childOf(this);
  listDraft: OrderSingle[] = OrderSingle.hasMany(this);
  tempOrderProduct: OrderProductSingle = OrderProductSingle.childOf(this);
  openPayment?: boolean = false;
  openPaymentSubmit?: boolean = false;
  openProductPicker?: boolean = false;

  crpIds:number[] = [];

  crpList: CrpClass[] = new Array<CrpClass>();
  incomingCrp : CrpClass[] = new Array<CrpClass>();

  unread =0;

  filter = "";
  filterDate = dateFormat(new Date(), "yyyy-MM-dd");
  
  isCancel = false;

  notes=""

  showLabel = false;

  labels = new Array<OrderProductSingle>();

  tempLabels:TempClass[] = new Array<TempClass>();

  loading:boolean=false;
  isSaving:boolean=false;

  init(){
    // this.tempOrder=new OrderSingle()
     //this.tempOrderProduct=new OrderProductSingle()
    // this.updateOrder=new OrderItem()
    this.listDraft=[]
    this.newTempOrder()
  }

  get getList() {
    return this.list.filter((item) => {
      let match = true;
      const fs = this.filter.toLowerCase();
      const fd = dateFormat(new Date(this.filterDate), "yyyy-MM-ddd");
      if (!!fs && !!item.additional_customer_name) {
        match = fuzzyMatch(fs, item.additional_customer_name.toLowerCase());
      } else if (!!fs && !item.additional_customer_name) {
        match = false;
      }
      if (!!fs && !!item.sales_order_number) {
        match = fuzzyMatch(fs, item.sales_order_number.toLowerCase());
      } else if (!!fs && !item.sales_order_number) {
        match = false;
      }
      if (!!this.filterDate && !!item.sales_order_date) {
        match =
          !!match && fd === dateFormat(item.sales_order_date, "yyyy-MM-ddd");
      }
      return match;
    });
  }

  get currentOrder(): OrderSingle {
    let idCurrentOrder = this.idCurrentOrder;
    let currentOrder = this.listDraft.find((x) => x.id === idCurrentOrder);
    if (!!currentOrder) return currentOrder;
    return new OrderSingle();
  }

  get getDetailOrder(): OrderItem {
    let currentOrder = this.list.find((x) => x.id === this.idDetailOrder);
    
    if (!!currentOrder) {

      let crp = this.crpList.find(x => x.id === currentOrder!!.id)
      if (!!crp) {
        crp!!.isRead=true
        this.setUnreadCount()
      }
      return currentOrder;
    }
    return new OrderItem();
  }

  get getListNonCancelled() {
    return this.list.filter((item) => {
      let match = true;
      const fs = this.filter.toLowerCase();
      const fd = dateFormat(new Date(this.filterDate), "yyyy-MM-ddd");
      if (!!fs && !!item.additional_customer_name) {
        match = fuzzyMatch(fs, item.additional_customer_name.toLowerCase());
      } else if (!!fs && !item.additional_customer_name) {
        match = false;
      }
      if (!!fs && !!item.sales_order_number) {
        match = fuzzyMatch(fs, item.sales_order_number.toLowerCase());
      } else if (!!fs && !item.sales_order_number) {
        match = false;
      }
      if (!!this.filterDate && !!item.sales_order_date) {
        match =
          !!match && fd === dateFormat(item.sales_order_date, "yyyy-MM-ddd");
      }

       match =  match && (item.so_status !== "cancelled") && (item.so_status !== "submitted")
       && (item.payment_method !== "") && (item.payment_method !== "null") && (!!item.payment_method)
      return match;
    });
  }

  get getSummary(): number {
    
    return this.getListNonCancelled.reduce((accumulator, current)=>accumulator +(current.grand_total),0)
  }

  getDetailOrderLabel() {
    //: OrderProductSingle[]
    //var labelOrder:any
    // var labelOrder = new Array<OrderProductSingle>();

    let currentOrder = this.list.find((x) => x.id === this.idDetailOrder);
    
    this.tempLabels=[]

    this.labels = [];
    var y = 0;
    
    if (!!currentOrder) {
      currentOrder.items.forEach((i: OrderProductSingle) => {
        for (let p = 1; p <= i.qty; p++) {
          i.tempId = y;
          i.checklabel = 1;

          var x = new OrderProductSingle();
          x.product_name = i.product_name;
          x.complement = i.complement;
          x.checklabel = i.checklabel;
          x.tempId = y;

          // console.log(x.product_name);
          // console.log(x.complement_str);
          

          this.labels[y] = x;

          this.labels[y].checklabel = 1;
          this.labels[y].tempId = y;
          
          

          y++;

          // i.product_name
        }
      });
      
    }
  }

  updateChecked(x: number) {
    if (this.labels[x].checklabel === 1) {
      this.labels[x].checklabel = 0;
    } else {
      this.labels[x].checklabel = 1;
    }
    // console.log(this.labels[x].product_name +": "+this.labels[x].checklabel)
  }
  get getLabel(): OrderProductSingle[] {
    return (this.labels = this.labels.filter((_obj) => true));
  }

  get customerByMember(): CustomerSingle[] {
    return CustomerStore.list.filter((x) => {
      let match = false;
      if (!!x.phone1 && !!this.tempOrder?.phone) {
        match = fuzzyMatch(String(x.phone1), String(this.tempOrder?.phone));
      }
      if (!!x.name && !!this.tempOrder?.name) {
        match =
          !!match ||
          !!fuzzyMatch(
            String(x.name).toLowerCase(),
            String(this.tempOrder?.name.toLowerCase())
          );
      }
      return match;
    });
  }

  async load() {
    this.loading=true

    var dateNow = new Date(this.filterDate)

    var date_from = new Date(this.filterDate);
    date_from.setDate(dateNow.getDate() - 1);

    var date_to = new Date(this.filterDate);
    date_to.setDate(dateNow.getDate() + 1);

    var so_date_from = dateFormat(date_from, "yyyy-MM-dd");
    var so_date_to = dateFormat(date_to, "yyyy-MM-dd");
    

    SalesAPI.getList(so_date_from,so_date_to,this.filter).then((res) => {
      
      this._loadJSON({
        list: res,
      });
      
      this.storedSO()
      
      this._loadJSON({
        loading:false
      });
    });
  }


  async scan() {
    this.loading=true
    
    SalesAPI.getList("","",this.filter).then((res) => {
      
      this._loadJSON({
        list: res,
      });
      // alert(JSON.stringify(res))
      if(res.length>0){
        this.idDetailOrder=res[0].id
        this.filterDate = dateFormat(new Date(OrderStore.getList[0].sales_order_date), "yyyy-MM-dd")
      }else{
        this.idDetailOrder=0
        this.filterDate = dateFormat(new Date(), "yyyy-MM-dd")
      }
      
      this.storedSO()
      
      this._loadJSON({
        loading:false
      });
    });
  }
  // async hapticsVibrate(){
  //   await Haptics.vibrate()
  // };

  storedSO(){
    //let Ids = this.getListCRP.map(a => a.id);

    let Ids = this.getListCRP

    
    this.incomingCrp=[];
    for(var i=0; i<Ids.length; ++i) {
      var crp = new CrpClass()
      crp.id=Ids[i].id
      crp.date=dateFormat(new Date(Ids[i].sales_order_date), "yyyy-MM-ddd");
      
      crp.isRead=false

      this.incomingCrp.push(crp)
    }

    const temp = this.crpList.length
    this.crpList = this.mergeDiffs(this.crpList,this.incomingCrp)

    // const temp = this.crpIds
    // this.crpIds = this.arrayUnique(this.crpIds.concat(Ids));
    this.setUnreadCount()
    console.log(this.crpList.length+" > "+temp)
    if(this.crpList.length>temp){
      this.playAudio()
    }
    
  }

  @observable
  get unreadCount(){
    const fd = dateFormat(new Date(this.filterDate), "yyyy-MM-ddd");
    let count= this.crpList.filter((x) => {
      let match = false;
      match = (x.date===fd) && (x.isRead===false)

      return match;
    })


    console.log(JSON.stringify(count))
    console.log(count.length)
    return count.length
  }

  setUnreadCount(){
    const fd = dateFormat(new Date(this.filterDate), "yyyy-MM-ddd");
    let count= this.crpList.filter((x) => {
      let match = false;
      match = (x.date===fd) && (x.isRead===false)

      return match;
    })

    this.unread = count.length
    console.log("unreeaded: "+this.unread)
  }

  

  mergeDiffs(current:CrpClass[], newCrp:CrpClass[]) {
    let result = current

    for(var i=0; i<newCrp.length; ++i) {
      let crp = current.find(x => x.id === newCrp[i].id)
      if(!!crp){
        //Do Nothing
      }else{
        result.push(newCrp[i])
      }
    }

    return result;   
}

  arrayUnique(array:number[]) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}


  get getListCRP() {
    return this.list.filter((item) => {
      let match = true;
      const fs = "CRP";
      const fd = dateFormat(new Date(), "yyyy-MM-ddd");

      if (!!fs && !!item.src) {
        match = (fs===item.src);
      }

      //filter date now
      if (!!this.filterDate && !!item.sales_order_date) {
        match =
          !!match && fd === dateFormat(item.sales_order_date, "yyyy-MM-ddd");
      }

       match =  match && (item.so_status !== "cancelled")
      return match;
    });
  }


  async reloadDraft() {
    this._loadJSON({
      listDraft: [],
    });
  }

  setOrderProduct(product: ProductSingle) {
    const pc = product.complement.filter((x: any) => !!x.is_default);
    this.tempOrderProduct._loadJSON({
      id: new Date().getTime(),
      id_product: product.id,
      product_name: product.product_name,
      quantity: 1,
      qty: 1,
      price: product.price,
      mode: "add",
      complement: pc,
    });

    this.tempOrder._loadJSON({
      unique_key: this.generateKey()
    });
  }

  initOrderProduct() {
    this.tempOrderProduct._loadJSON(new OrderProductSingle()._json);
  }

  newTempOrder() {
    console.log(this.generateKey())
    this.tempOrder._loadJSON({
      id: new Date().getTime(),
      unique_key: this.generateKey(),
      name: "Guest",
      phone: "",
      items: [],
    });
  }

  generateKey(){
    let key :String = ConfigStore.id_client.toString(16)+
      ":"+ConfigStore.id_outlet.toString(16)+
      ":"+parseInt(ConfigStore.id).toString(16)+
      ":"+( new Date().getTime()).toString(16)+
      ":"+this.getRandomInt().toString(16)
      
    return key
  }
   getRandomInt() {
    let min = Math.ceil(256);
    let max = Math.floor(4095);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async saveDraftOrder() {
    let listDraft = [...this.listDraft];
    let temp: any = { ...this.tempOrder };
    if (!!temp) {
      let index = listDraft.findIndex(
        (x) =>
          x.id === temp?.id ||
          (!!temp?.customer_code && x.customer_code === temp?.customer_code) ||
          (!!temp?.purchase_order_number &&
            x.purchase_order_number === temp?.purchase_order_number)
      );
      
      if (temp.id_customer) {
        
        let productMember = await ProductStore.loadProductMember(
          temp.id_customer
        );
        runInAction(()=>{
          //temp.deleteOrderItems();
          //this.currentOrder.deleteOrderItems()
          temp.productMember=productMember
        })
        
      }else{
        runInAction(()=>{
          //temp.deleteOrderItems();
          this.currentOrder.deleteOrderItems()
          temp.productMember=[];
        })
        
      }
      if (index === -1) {
        listDraft.push(temp);
        index = listDraft.length - 1;
      } else {
        listDraft[index] = temp;
      }
      this._loadJSON({
        idCurrentOrder: listDraft[index].id,
        listDraft,
      });
    }
  }

  updateTempOrder(id: number) {
    let item = this.listDraft.find((x) => x.id === id);
    if (!!item) {
      this.tempOrder._loadJSON(item._json);
    }
  }

  deleteDraftOrder(id: number) {
    let listDraft = [...this.listDraft];
    let index = listDraft.findIndex((x) => x.id === id);
    let active = this.currentOrder.id === id;
    let idCurrentOrder = this.idCurrentOrder;
    if (!!active) {
      idCurrentOrder = listDraft[0].id;
    }
    listDraft.splice(index, 1);
    this._loadJSON({
      idCurrentOrder,
      listDraft,
    });
  }

  async setUpdateOrder(order?: OrderItem) {
    const id = order?.id;
    const id_customer = order?.id_customer;
    if (id !== undefined && id > -1) {
      let item = this.list.find((x) => x.id === id);

      if (!!item) {
        this.updateOrder._loadJSON(item._json);
        if (!!id_customer) {
          let productMember = await ProductStore.loadProductMember(id_customer);
          this.updateOrder.productMember._loadJSON(productMember);
        }
      }
    } else {
      let a = new OrderItem();
      this.updateOrder._loadJSON(a._json);
    }
  }

  async updateQueue(order?: OrderItem) {
    const id = order?.id;
    const id_customer = order?.id_customer;
    if (id !== undefined && id > -1) {
      let item = this.list.find((x) => x.id === id);

      if (!!item) {
        this.updateOrder._loadJSON(item._json);
        let res: any = await SalesAPI.updateQueue({
          id: item.q_id,
          status: item.q_status+1,
        });
        if (res.status) {
          this.setUpdateOrder();
        }
      }
    }
      
  }

  async checkout() {
    let listDraft = [...this.listDraft];
    let item :OrderSingle = OrderStore.currentOrder._json;
    item.items = item.items.map((x: any) => ({
      ...x,
      id: null,
    }));

    item.sales_order_date=dateFormat(new Date(), "yyyy-MM-dd")
    runInAction(()=>{
      this.isSaving=true
    })
    const res: any = await SalesAPI.save("new", item);

    

    if (res.status) {
      if (res.data.so_status === "paid") {
        printStruk(res.data);
      }
      let idx = listDraft.findIndex((x) => x.id === item.id);
      let idCurrentOrder = this.idCurrentOrder;
      if (idx > -1) {
        listDraft.splice(idx, 1);
      }
      if (listDraft.length > 0) {
        this.idCurrentOrder = listDraft[0].id;
      }
      //  else {
      //   let n = new OrderSingle()._loadJSON({
      //     id: new Date().getTime(),
              
      //     name: "Guest",
      //     phone: "",
      //   });
      //   listDraft.push(n);
      //   idCurrentOrder = n.id;
      // }
      this._loadJSON({
        idCurrentOrder,
        listDraft,
        openPayment: false,
      });
      //await alert("Data telah disimpan.");
      OrderStore.load();
      navigate("order-list");
      this.newTempOrder()
      sendToTotem(this.tempOrder._json)
    }
    runInAction(()=>{
      this.isSaving=false
    })
  }


  async checkoutAndComplete() {
    
    let listDraft = [...this.listDraft];
    let item = OrderStore.currentOrder._json;
    item.items = item.items.map((x: any) => ({
      ...x,
      id: null,
    }));
    item.is_complete = "Y";
    this.loading =true
    this.isSaving=true
    const res: any = await SalesAPI.save("new", item);
    
    
    if (res.status) {
      if (res.data.so_status === "paid" || res.data.so_status === "complete") {
        printStruk(res.data);
      }
      let idx = listDraft.findIndex((x) => x.id === item.id);
      let idCurrentOrder = this.idCurrentOrder;
      if (idx > -1) {
        listDraft.splice(idx, 1);
      }
      if (listDraft.length > 0) {
        this.idCurrentOrder = listDraft[0].id;
      }
      //  else {
      //   let n = new OrderSingle()._loadJSON({
      //     id: new Date().getTime(),
      //     name: "Guest",
      //     phone: "",
      //   });
      //   listDraft.push(n);
      //   idCurrentOrder = n.id;
      // }
      this._loadJSON({
        idCurrentOrder,
        listDraft,
        openPayment: false,
      });
      
      OrderStore.load();
      navigate("order-list");
      this.newTempOrder()
      sendToTotem(this.tempOrder._json)
    }
    runInAction(()=>{
      this.loading =false
      this.isSaving=false
    })
  }


  async update(isPayment = false) {
    let item = OrderStore.updateOrder._json;
    if (!isPayment) {
      delete item.payment_status;
      delete item.payment_method;
    }
    item.items = item.items.map((x: any) => ({
      ...x,
      payment_status: "Now",
      id: !x.status ? null : x.id,
    }));
    const res: any = await SalesAPI.save("update", item);

    if (res.status) {
      if (res.data.so_status === "paid" || res.data.so_status === "complete") {
        printStruk(res.data);
      }
      this.setUpdateOrder();
      this._loadJSON({
        openPaymentSubmit: false,
      });
      //alert("Data telah disimpan.");
      OrderStore.openProductPicker = false;

      OrderStore.load();
      this.newTempOrder()
      sendToTotem(this.tempOrder._json)
    }
  }


  async updateAndComplete(isPayment = false) {
    let item = OrderStore.updateOrder._json;
    if (!isPayment) {
      delete item.payment_status;
      delete item.payment_method;
    }else{
      item.is_complete = "Y";
      item.is_just_paid = "Y";
    }
    item.items = item.items.map((x: any) => ({
      ...x,
      payment_status: "Now",
      id: !x.status ? null : x.id,
    }));
    
    const res: any = await SalesAPI.save("update", item);

    if (res.status) {
      
      if (res.data.so_status === "complete") {
        printStruk(res.data);
      }
      
      this.setUpdateOrder();
      this._loadJSON({
        openPaymentSubmit: false,
      });
      //alert("Data telah disimpan.");
      OrderStore.openProductPicker = false;
      
      OrderStore.load();
      this.newTempOrder()
      sendToTotem(this.tempOrder._json)
    }
  }

  async confirmCancel() {
    // let conf = window.confirm("Apakah anda yakin akan membatalkan pesanan?");
    // if (!!conf) {
      this._loadJSON({
        isCancel: true,
      });
    // }
  }

  async cancel() {
    let item = OrderStore.updateOrder._json;
    item.is_cancel = "Y";
    const res: any = await SalesAPI.save("update", item);
    if (res.status) {
      this.setUpdateOrder();
      //alert("Pesanan telah dibatalkan.");
      this.load();
      this._loadJSON({
        isCancel: false,
      });
    }
  }

  async complete() {
    // let conf = window.confirm("Apakah anda yakin pesanan sudah selesai?");
    // if (!!conf) {
      let item = OrderStore.updateOrder._json;
      item.is_complete = "Y";
      this.loading=true
      const res: any = await SalesAPI.save("update", item);
      this.loading=false
      if (res.status) {
        this.setUpdateOrder();
        //alert("Pesanan telah selesai.");
        this.load();
      }
    // }
  }

  async serve() {
    // let conf = window.confirm("Apakah anda yakin pesanan siap diambil?");
    // if (!!conf) {
      let item = OrderStore.updateOrder._json;
      item.is_served = "Y";
      this.loading=true
      const res: any = await SalesAPI.save("update", item);
      if (res.status) {
        this.setUpdateOrder();
        //alert("Pesanan siap diambil.");
        this.load();
      }
      this.loading=false
    // }
  }

  playAudio() {
    const audioEl = document.getElementById("audio-element")
    if(!!audioEl){
      const audioE2 :HTMLMediaElement  = audioEl as HTMLMediaElement
      var playPromise = audioE2.play()
      console.log(playPromise)
      if (!!playPromise) {
        playPromise.then(function() {
          console.log("Played")
        }).catch(function(error:any) {
          console.log("Error")
        });
      }

      
    }
    
  }
}

const OrderStore = OrderRepository.create({
  localStorage: true,
  storageName: "OrderRepository",
});

class TempClass extends Model {
  product_name="---";
  complement_str="--";
}

class CrpClass extends Model {
  id=0;
  date="";
  isRead=false;
}
export default OrderStore;
