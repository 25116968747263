import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { runInAction } from "mobx";

const save = async (mode: string, data: any) => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/postPreOrder`;
  const rest:any={
    url,
    method: "post",
    timeout:10000,
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      mode,
      ...data,
    },
  }  

  console.log(JSON.stringify(rest))

  

  const res: any = await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      mode,
      ...data,
    },
  });

  if (typeof res === "object" && res.status!="0" && res.status!=false) {

    return { status: true, data: res.data };
  } else if (typeof res === "object" && !res.status) {

    runInAction(()=>{
      OrderStore.loading =false
      OrderStore.isSaving=false
    })
    
    alert(res.message);
    return false;
  }
  
  alert("Gagal menyimpan. [001]");
  return false;
};

const getList = async (from:String="", to:String="", so_no:String="") => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListSalesOrder`;


  const res: any = await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      so_date_from: from,
      so_date_to: to,
      so_no: so_no,
      so_status: "",
      do_status: "",
      limit: "",
      filterDate:""
    },
  });

  console.log(JSON.stringify(res));


  console.log(JSON.stringify({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      so_date_from: from,
      so_date_to: to,
      so_no: so_no,
      so_status: "",
      do_status: "",
      limit: "",
    },
  }));

  if (Array.isArray(res)) {
    // console.log(JSON.stringify(res))
    return res;
  }
  return [];
};

const getListDeliveryItem = async (data?: any) => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListDeliveryItem`;
  const res: any = await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      so_date_from: "",
      so_date_to: "",
      so_no: "",
      so_status: "",
      do_status: "",
      limit: "",
    },
  });

  if (Array.isArray(res)) {
    return res;
  }
  return [];
};

const updateStatus = async (data: any) => {
  let url = `${ConfigStore.server_url}/index.php?r=apiServicePOS/UpdateStatusDeliveryItem`;
  const res: any = await api({
    url,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      ...data,
    },
  });

  if (typeof res === "object" && !!res.status) {
    return true;
  }
  alert("Gagal menyimpan. [001]");
  return false;
};


const updateQueue = async (data: any) => {
  const res: any = await api({
    url: `${ConfigStore.server_url}/index.php?r=Api`,
    method: "post",
    data: {
      token: ConfigStore.app_name,
      user_token: ConfigStore.user_token,
      mode : "edit",
      model : "TOutletQueueDateNumber",
      attributes : data
    },
  });
  
  // console.log(JSON.stringify({
  //   url: `${ConfigStore.server_url}/index.php?r=Api`,
  //   method: "post",
  //   data: {
  //     token: ConfigStore.app_name,
  //     user_token: ConfigStore.user_token,
  //     mode : "edit",
  //     model : "TOutletQueueDateNumber",
  //     attributes : data
  //   },
  // }))

  if (!!res && res.status=="200") {
    return res;
  }
  
  // alert(JSON.stringify(res))
  
  return {};
};

const SalesAPI = {
  save,
  getList,
  getListDeliveryItem,
  updateStatus,
  updateQueue
};

export default SalesAPI;
